.FeedsManager-Content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 48px;
  padding: 32px 0px;
  .Title {
    font-size: 18px;
    font-weight: 500; }
  .MainInfo {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 64px; }

  .Buttons {
    display: flex;
    .Button {
      &:not(:last-child) {
        margin-right: 16px; } } } }
