.Settings-Content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  padding: 24px 0px;
  .Titles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px; }
  .Title {
    font-size: 18px;
    font-weight: 500; }
  .MainInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    .Field-Container {
      grid-column: 1/-1; } }

  .Buttons {
    display: flex;
    .Button {
      &:not(:last-child) {
        margin-right: 16px; } } } }
